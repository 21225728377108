// axios
import axios from 'axios'
import constants from './constants';
import { isUserLoggedIn, getAccessToken, logout } from './utils';

const baseURL = "";

let axiosObj = axios.create({});

if(isUserLoggedIn()) {
  axiosObj = axios.create({
    baseURL: baseURL,
    // You can add your headers here
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  });

  axiosObj.interceptors.response.use(onResponseSuccess, onRequestFailure);
}

function onResponseSuccess(response) {
  return response;
}

function onRequestFailure(err) {
  const { response } = err;
  if (response && response.status === 401) {
      logout();
      window.location.href = `${constants.apiBaseUrl}/login`;
  }
  // throw response;
  return response;
}

export default axiosObj;