import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    InputGroup,
    FormGroup,
    MenuItem,
} from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import "@blueprintjs/select/lib/css/blueprint-select.css";
import { t } from 'polotno/utils/l10n';
import { defaultThemes, defaultStyles } from '../assets/data';

export const Publish = observer(({ store, project }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const handleClose = useCallback(() => setIsOpen(false), []);
    
    const [title, setTitle] = useState(project.name ? project.name : 'Untitled Design');
    useEffect(() => { setTitle(project.name ? project.name : 'Untitled Design') }, [project.name]);
    
    const [color, setColor] = useState(project.color ? project.color : '');
    useEffect(() => { setColor(project.color ? project.color : '') }, [project.color]);
    
    const [theme, setTheme] = useState(project.theme ? project.theme : 'happy');
    useEffect(() => { setTheme(project.theme ? project.theme : 'happy') }, [project.theme]);

    const [style, setStyle] = useState(project.style ? project.style : 'urban street');
    useEffect(() => { setStyle(project.style ? project.style : 'urban street') }, [project.style]);
    
    return (
        <>
            <Button
                minimal
                text={t('toolbar.publish')}
                intent="primary"
                loading={saving}
                onClick={() => {
                    setIsOpen(true)
                }}
            />
            <Dialog title="Publish Design" isOpen={isOpen} onClose={handleClose}>
                <DialogBody>
                    <p className="bp5-text-large">Add some details to your design</p>
                    <FormGroup
                        label="Title"
                        labelFor="title-input"
                    >
                        <InputGroup
                            id="title-input"
                            fill={false}
                            placeholder="Title"
                            type="text"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Color"
                        labelFor="color-input"
                    >
                        <InputGroup
                            id="color-input"
                            fill={false}
                            placeholder="Red, Blue, Green, etc."
                            type="text"
                            value={color}
                            onChange={(e) => {
                                setColor(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Theme"
                        labelFor="theme-input"
                    >
                        <Suggest
                            activeItem={theme}
                            items={defaultThemes}
                            selectedItem={theme}
                            itemPredicate={(query, film, _index, exactMatch) => {
                                const normalizedTitle = film?.toLowerCase();
                                const normalizedQuery = query.toLowerCase();

                                if (exactMatch) {
                                    return normalizedTitle === normalizedQuery;
                                } else {
                                    return normalizedTitle.includes(normalizedQuery)
                                }
                            }}
                            itemRenderer={(val, itemProps) => {
                                return (
                                    <MenuItem
                                        key={val}
                                        text={val}
                                        onClick={(elm) => {
                                            setTheme(elm.target.textContent)
                                        }}
                                        active={itemProps.modifiers.active}
                                    />
                                );
                            }}
                            onItemSelect={() => { }}
                            tagRenderer={(item) => item}
                            inputValueRenderer={(item) => item.toString()}
                            popoverProps={{ minimal: true }}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Style"
                        labelFor="style-input"
                    >
                        <Suggest
                            activeItem={style}
                            items={defaultStyles}
                            selectedItem={style}
                            itemPredicate={(query, film, _index, exactMatch) => {
                                const normalizedTitle = film?.toLowerCase();
                                const normalizedQuery = query.toLowerCase();

                                if (exactMatch) {
                                    return normalizedTitle === normalizedQuery;
                                } else {
                                    return normalizedTitle.includes(normalizedQuery)
                                }
                            }}
                            itemRenderer={(val, itemProps) => {
                                return (
                                    <MenuItem
                                        key={val}
                                        text={val}
                                        onClick={(elm) => {
                                            setStyle(elm.target.textContent)
                                        }}
                                        active={itemProps.modifiers.active}
                                    />
                                );
                            }}
                            onItemSelect={() => { }}
                            tagRenderer={(item) => item}
                            inputValueRenderer={(item) => item.toString()}
                            popoverProps={{ minimal: true }}
                        />
                    </FormGroup>
                </DialogBody>
                <DialogFooter actions={
                    <Button intent="primary" loading={saving} text="Publish" disabled={!title || !color || !theme || !style} onClick={() => {
                        setSaving(true);
                        window.project.name = title;
                        window.project.color = color;
                        window.project.theme = theme;
                        window.project.style = style;

                        project.requestSave();
                        project.publish(title, color, theme, style);
                        setTimeout(() => {setIsOpen(false); setSaving(false);}, 5000);
                    }} />
                } />
            </Dialog>
        </>
    );
});
