import { jwtDecode } from "jwt-decode";
import constants from './constants';
import Cookies from 'js-cookie';

export function isUserLoggedIn() {
    const accessToken = Cookies.get('access_token'); // localStorage.getItem("access_token");

    if (accessToken) {
        const exp = jwtDecode(accessToken).exp;

        if (new Date(Date.now()) < new Date(exp * 1000)) {
            return true;
        }
    }

    logout();
    return false;
}

export function getAccessToken() {
    const accessToken = Cookies.get('access_token'); // localStorage.getItem("access_token");

    if (accessToken) {
        return accessToken;
    }
    return null;
}

export function logout() {
    // localStorage.removeItem("access_token");
    Cookies.remove('access_token', { path: '/', domain: constants.cookieDomain });
}