import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button,
  Navbar,
  Alignment,
  // AnchorButton,
  NavbarDivider,
  EditableText,
  // Popover,
} from '@blueprintjs/core';
import { t } from 'polotno/utils/l10n';

// import FaGithub from '@meronex/icons/fa/FaGithub';
// import FaDiscord from '@meronex/icons/fa/FaDiscord';
// import FaTwitter from '@meronex/icons/fa/FaTwitter';
// import BiCodeBlock from '@meronex/icons/bi/BiCodeBlock';
// import MdcCloudAlert from '@meronex/icons/mdc/MdcCloudAlert';
// import MdcCloudCheck from '@meronex/icons/mdc/MdcCloudCheck';
// import MdcCloudSync from '@meronex/icons/mdc/MdcCloudSync';
import styled from 'polotno/utils/styled';

import { useProject } from '../project';

// import { FileMenu } from './file-menu';
// import { DownloadButton } from './download-button';
import { PrintButton } from './print-button';
// import { UserMenu } from './user-menu';
// import { CloudWarning } from '../cloud-warning';
import { Logo } from './logo';
// import { t } from 'polotno/utils/l10n';
import { Publish } from './publish';
import axiosObj from '../axios';
import constants from '../constants';

import "../assets/style.css";

const NavbarContainer = styled('div')`
  white-space: nowrap;

  @media screen and (max-width: 500px) {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100vw;
  }
`;

const NavInner = styled('div')`
  @media screen and (max-width: 500px) {
    display: flex;
  }
`;

// const Status = observer(({ project }) => {
//   const Icon = !project.cloudEnabled
//     ? MdcCloudAlert
//     : project.status === 'saved'
//     ? MdcCloudCheck
//     : MdcCloudSync;
//   return (
//     <Popover
//       content={
//         <div style={{ padding: '10px', maxWidth: '300px' }}>
//           {!project.cloudEnabled && (
//             <CloudWarning style={{ padding: '10px' }} />
//           )}
//           {project.cloudEnabled && project.status === 'saved' && (
//             <>
//               You data is saved with{' '}
//               <a href="https://puter.com" target="_blank">
//                 Puter.com
//               </a>
//             </>
//           )}
//           {project.cloudEnabled &&
//             (project.status === 'saving' || project.status === 'has-changes') &&
//             'Saving...'}
//         </div>
//       }
//       interactionKind="hover"
//     >
//       <div style={{ padding: '0 5px' }}>
//         <Icon className="bp5-icon" style={{ fontSize: '25px', opacity: 0.8 }} />
//       </div>
//     </Popover>
//   );
// });

export default observer(({ store }) => {
  const project = useProject();
  const [isDesignPartner, setIsDesignPartner] = useState(false);

  const print = () => {
    project.requestSave(true);
    // project.print();
  };

  useEffect(() => {
    (async () => {
      const response = await axiosObj.get(`${constants.apiBaseUrl}/design-partner`);
      if(response.data?.data?.status === 'ACTIVE') {
        setIsDesignPartner(true);
      }
    })()
  }, [])

  return (
    <NavbarContainer className="bp5-navbar">
      <NavInner>
        <Navbar.Group align={Alignment.LEFT}>
          {/* <FileMenu store={store} project={project} /> */}
          <Logo />
          {/* <div
            style={{
              paddingLeft: '20px',
              maxWidth: '200px',
              alignItems: 'center'
            }}
          >
            <EditableText
              value={window.project.name}
              placeholder="Untitled"
              onChange={(name) => {
                window.project.name = name;
                window.project.requestSave();
              }}
            />
          </div> */}
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          {/* <Status project={project} /> */}

          {/* <AnchorButton
            href="https://polotno.com"
            target="_blank"
            minimal
            icon={
              <BiCodeBlock className="bp5-icon" style={{ fontSize: '20px' }} />
            }
          >
            API
          </AnchorButton> */}

          {/* <AnchorButton
            minimal
            href="https://github.com/lavrton/polotno-studio"
            target="_blank"
            icon={
              <FaGithub className="bp5-icon" style={{ fontSize: '20px' }} />
            }
          ></AnchorButton>
          <AnchorButton
            minimal
            href="https://twitter.com/lavrton"
            target="_blank"
            icon={
              <FaTwitter className="bp5-icon" style={{ fontSize: '20px' }} />
            }
          ></AnchorButton> */}
          {/* <NavbarDivider /> */}
          {/* <DownloadButton store={store} /> */}

          <div
            style={{
              maxWidth: '100px',
              alignItems: 'center',
              margin: '5px',
              padding: '5px',
              border: '1px solid lightgray', 
              borderRadius: '5px'
            }}
          >
            <EditableText
              value={window.project.name}
              placeholder="Untitled"
              onChange={(name) => {
                window.project.name = name;
                window.project.requestSave();
              }}
            />
          </div>
          <NavbarDivider />
          {/* <Button
            minimal
            text={t('toolbar.publish')}
            intent="primary"
            // loading={saving}
            onClick={() => {
              project.save();
              project.publish();
            }}
          /> */}

          {isDesignPartner && <Publish project={project} />}
          {isDesignPartner && <NavbarDivider />}

          {/* <PrintButton store={store} /> */}
          <Button
            minimal
            icon="ship"
            text={t('toolbar.print')}
            intent="primary"
            // loading={saving}
            onClick={() => print()}
          />
          {/* <NavbarDivider />
          <UserMenu store={store} project={project} /> */}
          {/* <NavbarHeading>Polotno Studio</NavbarHeading> */}
        </Navbar.Group>
      </NavInner>
    </NavbarContainer>
  );
});
