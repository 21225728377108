const defaultThemes = [
    "happy",
    "yoga",
    "barbeque",
    "beverage",
    "whiskey",
    "surfing",
    "adventure",
    "event",
    "celebration",
    "dad",
    "travel",
    "food",
    "gaming",
    "pet",
    "spa",
    "earth",
    "motivation",
    "world",
    "clothing brand",
    "summer",
    "water",
    "dog",
    "nature",
    "beauty",
    "beach",
    "love",
    "ocean",
    "cleaning",
    "birthday",
    "wellness",
    "house",
    "bar",
    "wildlife",
    "techno",
    "season",
    "photography",
    "japan",
    "restaurant",
    "turkey",
    "horror",
    "sports",
    "city",
    "christmas",
    "holiday",
    "funny",
    "fashion",
    "technology",
    "santa",
    "space",
    "future",
    "winter",
    "peace",
    "game",
    "faith",
    "school",
    "beer",
    "community",
    "brewery",
    "broken heart",
    "friends",
    "hobby",
    "nurse",
    "baseball",
    "festival",
    "skate",
    "fishing",
    "valentines",
    "vacation",
    "construction",
    "graduation",
    "business",
    "meat",
    "bakery",
    "small business",
    "fitness",
    "greek",
    "hiking",
    "diy",
    "devil",
    "gym",
    "autumn",
    "thanksgiving",
    "california",
    "teacher",
    "education",
    "shop",
    "family",
    "cafe",
    "caffeine",
    "spring",
    "camping",
    "wilderness",
    "outdoor",
    "racing",
    "coffee",
    "art",
    "real estate",
    "basketball",
    "promotion",
    "movie",
    "scary",
    "halloween",
    "music",
    "smiley face",
    "usa",
    "america",
    "band",
    "mother's day",
    "metal",
    "football",
    "independence day",
    "4th of july",
    "university",
    "party",
    "new years",
    "congratulations",
    "dancing",
    "cat",
    "college",
    "soccer",
    "relationship",
    "marriage",
    "wedding",
    "military",
    "dj",
    "cooking",
    "golf",
    "father's day",
    "creepy",
    "boat",
    "farm",
    "barber",
    "pizza",
    "zombie",
    "mom",
    "easter",
    "freedom",
    "sale",
    "espresso",
    "quotes",
    "inspiration",
    "couple",
    "baby",
    "kids",
    "magic"
];

const defaultStyles = [
    "urban street",
    "neon",
    "graffiti",
    "cartoon",
    "bauhaus",
    "60s",
    "psychedelic",
    "black and white",
    "victorian",
    "luxury",
    "vintage",
    "futuristic",
    "retro",
    "dark",
    "ornate",
    "elegant",
    "grunge",
    "minimal",
    "groovy",
    "cute",
    "70s",
    "steampunk",
    "mystical",
    "90s",
    "anime",
    "typography",
    "2000s",
    "abstract",
    "simple",
    "feminine",
    "modern",
    "art deco",
    "handmade",
    "script",
    "organic",
    "kawaii",
    "scandinavian",
    "art nouveau",
    "punk",
    "floral",
    "3d",
    "brutalist",
    "gothic",
    "sci-fi",
    "clean",
    "y2k",
    "tattoo",
    "girly",
    "tech",
    "50s",
    "cyberpunk",
    "handwritten",
    "monochrome",
    "comic",
    "20s",
    "pop art",
    "western",
    "80s",
    "industrial",
    "watercolor",
    "sanborn"
];

module.exports = {
    defaultThemes,
    defaultStyles,
};