import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  NavbarDivider,
} from '@blueprintjs/core';

import "../assets/style.css";

export const Logo = observer(({ store }) => {
  return (
    <>
      <img className="mobile-hidden" src="/logo-full.png" alt="Deziral Logo" width="auto" height="32px" />
      <img className="mobile-visible" src="/logo.png" alt="Deziral Logo" width="auto" height="32px" />
      <NavbarDivider className="mobile-visible" />
    </>
  );
});
