import * as mobx from 'mobx';
import { createContext, useContext } from 'react';
import localforage from 'localforage';
import { v4 as uuidV4 } from 'uuid';

import * as api from './api';
import constants from './constants';

window.localforage = localforage;

export const ProjectContext = createContext({});

export const useProject = () => useContext(ProjectContext);

const getFromStorage = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

const setToStorage = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {}
};

class Project {
  id = uuidV4();
  name = 'Untitled';
  color = '';
  theme = '';
  style = '';
  user = {};
  skipSaving = false;
  cloudEnabled = false;
  status = 'saved'; // or 'has-changes' or 'saving' or 'loading'
  language = getFromStorage('polotno-language') || navigator.language || 'en';
  designsLength = 0;
  print_in_progress = false;

  constructor({ store }) {
    mobx.makeAutoObservable(this);
    this.store = store;

    store.on('change', () => {
      this.requestSave();
    });

    // setInterval(() => {
    //   mobx.runInAction(() => {
    //     this.cloudEnabled = window.puter?.auth?.isSignedIn();
    //   });
    // }, 100);
  }

  setLanguage(lang) {
    this.language = lang;
    setToStorage('polotno-language', lang);
  }

  requestSave(print = false) {
    this.status = 'has-changes';
    if(print === true) this.print_in_progress = true;
    if (this.saveTimeout) {
      return;
    }
    this.saveTimeout = setTimeout(() => {
      this.saveTimeout = null;
      // skip autosave if no project opened
      if(!print) this.save();
      else {
        Promise.all([this.save(), this.saveTransparent(), this.print()]).then((x) => {
          this.redirectToPrint(x[2]);
        });
      }
    }, 3000);
  }

  async firstLoad() {
    const deprecatedDesign = await localforage.getItem('polotno-state');
    if (deprecatedDesign) {
      this.store.loadJSON(deprecatedDesign);
      await localforage.removeItem('polotno-state');
      await this.save();
      return;
    }
    
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    const dzi = params.get('dzi');
    
    if(dzi) {
      await this.loadById(dzi);
    } else {
      const lastDesignId = await localforage.getItem('polotno-last-design-id');
      if (lastDesignId) {
        await this.loadById(lastDesignId);
      }
    }
  }

  async loadById(id) {
    this.id = id;
    await localforage.setItem('polotno-last-design-id', id);
    this.status = 'loading';
    try {
      const { storeJSON, name, color, theme, style } = await api.loadById({
        id,
      });
      if (storeJSON) {
        this.store.loadJSON(storeJSON);
      }
      this.name = name;
      this.color = color;
      this.theme = theme;
      this.style = style;
    } catch (e) {
      console.error(e);
      this.id = uuidV4();
      this.name = 'Untitled Design';
      this.color = '';
      this.theme = '';
      this.style = '';
      await localforage.removeItem('polotno-last-design-id');
    }
    this.status = 'saved';
  }

  updateUrlWithProjectId() {
    if (!this.id || this.id === 'local') {
      window.history.replaceState({}, null, `/`);
      return;
    }
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    params.set('id', this.id);
    window.history.replaceState({}, null, `/design/${this.id}`);
  }

  async save() {
    this.status = 'saving';
    console.log(this.id);
    const storeJSON = this.store.toJSON();
    const maxWidth = 1080;
    const canvas = this.store.pages.length
      ? await this.store._toCanvas({
          pixelRatio: maxWidth / this.store.activePage?.computedWidth,
          pageId: this.store.activePage?.id,
        })
      : // if there is no page, create a dummy canvas
        document.createElement('canvas');
    const blob = await new Promise((resolve) => {
      // canvas.toBlob(resolve, 'image/jpeg', 0.9);
      canvas.toBlob(resolve, 'image/png');
    });

    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.saveDesign({
          storeJSON,
          preview: blob,
          id: this.id,
          name: this.name,
          color: this.color,
          theme: this.theme,
          style: this.style,
        });
  
        if (res.status === 'saved') {
          this.id = res.id;
          await localforage.setItem('polotno-last-design-id', res.id);
        }

        this.status = 'saved';
        resolve(true);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  async saveTransparent() {
    const storeJSON = this.store.toJSON();
    const maxWidth = 1080;

    let transparent_storeJSON = JSON.parse(JSON.stringify(this.store.toJSON()));
    if(transparent_storeJSON["pages"][0]["background"]) {
      transparent_storeJSON["pages"][0]["background"] = "rgb(0, 0, 0, 0)";
    }
    
    this.store.loadJSON(transparent_storeJSON);
    const transparent_canvas = this.store.pages.length
      ? await this.store._toCanvas({
          pixelRatio: maxWidth / this.store.activePage?.computedWidth,
          pageId: this.store.activePage?.id,
        })
      : // if there is no page, create a dummy canvas
        document.createElement('canvas');
    const transparent_blob = await new Promise((resolve) => {
      // canvas.toBlob(resolve, 'image/jpeg', 0.9);
      transparent_canvas.toBlob(resolve, 'image/png');
    });

    this.store.loadJSON(storeJSON);

    return new Promise(async (resolve, reject) => {
      try {
        await api.saveTransparentDesign({
          transparent_storeJSON,
          transparent_preview: transparent_blob,
          id: this.id,
        });

        resolve(true);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  async duplicate() {
    this.id = uuidV4();
    this.save();
  }

  async clear() {
    this.store.clear();
    this.store.addPage();
    await localforage.removeItem('polotno-last-design-id');
  }

  async createNewDesign() {
    await this.clear();
    this.name = 'Untitled Design';
    this.id = uuidV4();
    this.store.openSidePanel('templates');
    console.log('saving');
    await this.save();
    console.log('saving done');
  }

  // async signIn() {
  //   await window.puter.auth.signIn();
  //   this.designsLength = await api.backupFromLocalToCloud();
  // }

  async publish(title, color, theme, style) {
    this.status = 'publishing';
    console.log(this.id);
    const storeJSON = this.store.toJSON();
    const maxWidth = 1080;
    const canvas = this.store.pages.length
      ? await this.store._toCanvas({
          pixelRatio: maxWidth / this.store.activePage?.computedWidth,
          pageId: this.store.activePage?.id,
        })
      : // if there is no page, create a dummy canvas
        document.createElement('canvas');
    const blob = await new Promise((resolve) => {
      canvas.toBlob(resolve, 'image/png');
    });
    try {
      api.publishDesign({
        storeJSON,
        preview: blob,
        id: this.id,
        name: title ? title : this.name,
        color, 
        theme, 
        style,
      });
    } catch (e) {
      console.error(e);
    }
    this.status = 'saved';
  }

  async print() {
    try {
      return new Promise(async (resolve, reject) => {
        try {
          this.print_in_progress = true;
          const { item_id } = await api.printDesign({
            front: {
              project_id: this.id
            }
          });
          this.print_in_progress = false;
  
          resolve(item_id);
        } catch (e) {
          console.error(e);
          reject(e);
        }
      });

      // window.location.href = `${constants.baseUrl}/print/${item_id}`;
    } catch (error) {
      console.error(error);
    }
  }

  redirectToPrint(item_id) {
    window.location.href = `${constants.baseUrl}/print/${item_id}`;
  }
}

export const createProject = (...args) => new Project(...args);
export default createProject;
