import { v4 as uuidV4 } from 'uuid';
// import localforage from 'localforage';
import axios from './axios';
import constants from './constants';

// const isSignedIn = () => {
//   return window.puter?.auth?.isSignedIn();
// };

// const withTimeout =
//   (fn, name) =>
//   async (...args) => {
//     const startTime = Date.now();
//     const timeoutId = setTimeout(async () => {
//       // Log timeout error with Sentry
//       const error = new Error('API call timeout');
//       const req = await fetch('https://api.puter.com/version');
//       const version = await req.json();

//       window.Sentry?.captureException(error, {
//         extra: {
//           function: name,
//           arguments: args,
//           elapsedTime: Date.now() - startTime,
//           user: await window.puter?.auth?.getUser(),
//           version,
//           size: JSON.stringify(args).length,
//         },
//       });
//     }, 15000);

//     try {
//       const result = await fn(...args);
//       clearTimeout(timeoutId);
//       return result;
//     } catch (error) {
//       clearTimeout(timeoutId);
//       throw error;
//     }
//   };

// const writeFile = withTimeout(async function writeFile(fileName, data) {
//   if (isSignedIn()) {
//     await window.puter.fs.write(fileName, data, { createMissingParents: true });
//   } else {
//     await localforage.setItem(fileName, data);
//   }
// }, 'writeFile');

// const readFile = withTimeout(async function readFile(fileName) {
//   if (isSignedIn()) {
//     return await window.puter.fs.read(fileName);
//   }
//   return await localforage.getItem(fileName);
// }, 'readFile');

// const deleteFile = withTimeout(async function deleteFile(fileName) {
//   if (isSignedIn()) {
//     return await window.puter.fs.delete(fileName);
//   }
//   return await localforage.removeItem(fileName);
// }, 'deleteFile');

// const readKv = withTimeout(async function readKv(key) {
//   if (isSignedIn()) {
//     return await window.puter.kv.get(key);
//   } else {
//     return await localforage.getItem(key);
//   }
// }, 'readKv');

// const writeKv = withTimeout(async function writeKv(key, value) {
//   if (isSignedIn()) {
//     return await window.puter.kv.set(key, value);
//   } else {
//     return await localforage.setItem(key, value);
//   }
// }, 'writeKv');

// export async function backupFromLocalToCloud() {
//   const localDesigns = (await localforage.getItem('designs-list')) || [];
//   for (const design of localDesigns) {
//     const storeJSON = await localforage.getItem(`designs/${design.id}.json`);
//     const preview = await localforage.getItem(`designs/${design.id}.jpg`);
//     await writeFile(`designs/${design.id}.json`, storeJSON);
//     await writeFile(`designs/${design.id}.jpg`, preview);
//   }
//   const cloudDesigns = (await window.puter.kv.get('designs-list')) || [];
//   cloudDesigns.push(...localDesigns);
//   await window.puter.kv.set('designs-list', cloudDesigns);
//   await localforage.removeItem('designs-list');
//   for (const design of localDesigns) {
//     await localforage.removeItem(`designs/${design.id}.json`);
//     await localforage.removeItem(`designs/${design.id}.jpg`);
//   }
//   return cloudDesigns.length;
// }

export async function listDesigns() {
  // return (await readKv('designs-list')) || [];
  const response = await axios.get(`${constants.apiBaseUrl}/project-list`);
  return response.data?.data || [];
}

export async function deleteDesign({ id }) {
  // const list = await listDesigns();
  // const newList = list.filter((design) => design.id !== id);
  // await writeKv('designs-list', newList);
  // await deleteFile(`designs/${id}.json`);
  // await deleteFile(`designs/${id}.jpg`);

  await axios.post(`${constants.apiBaseUrl}/project-delete/${id}`);
}

export async function loadById({ id }) {
  // let storeJSON = await readFile(`designs/${id}.json`);
  let storeJSON = await axios.get(`${constants.apiBaseUrl}/project-file/${id}`);
  storeJSON = storeJSON.data;
  const list = await listDesigns();
  const design = list.find((design) => design.id === id);
  // if it is blob, convert to JSON
  if (storeJSON instanceof Blob) {
    storeJSON = JSON.parse(await storeJSON.text());
  } else if (typeof storeJSON === 'string') {
    storeJSON = JSON.parse(storeJSON);
  }

  return { storeJSON, name: design?.title, color: design?.color, theme: design?.theme, style: design?.style };
}

export async function saveDesign({ storeJSON, preview, name, id, color, theme, style }) {
  console.log('saving');
  // if (!id) {
  //   id = nanoid(10);
  // }

  // const previewPath = `designs/${id}.jpg`;
  // const storePath = `designs/${id}.json`;

  const formData = new FormData();
  formData.append('project_json', JSON.stringify(storeJSON));
  formData.append('project_preview', preview);
  formData.append('title', name);
  formData.append('color', color);
  formData.append('theme', theme);
  formData.append('style', style);
  if(id) formData.append('project_id', id);

  await axios.post(`${constants.apiBaseUrl}/project`, formData);

  // await writeFile(previewPath, preview);
  console.log('preview saved');
  // await writeFile(storePath, JSON.stringify(storeJSON));

  // let list = await listDesigns();
  // const existing = list.find((design) => design.id === id);
  // if (existing) {
  //   existing.name = name;
  // } else {
  //   list.push({ id, name });
  // }

  // await writeKv('designs-list', list);
  return { id, status: 'saved' };
}

export async function saveTransparentDesign({ transparent_storeJSON, transparent_preview, id }) {
  console.log('saving');

  const formData = new FormData();
  formData.append('transparent_project_json', JSON.stringify(transparent_storeJSON));
  formData.append('transparent_project_preview', transparent_preview);
  if(id) formData.append('project_id', id);

  await axios.post(`${constants.apiBaseUrl}/transparent-project`, formData);

  console.log('preview saved');

  return { id, status: 'saved' };
}

export async function publishDesign({ storeJSON, preview, name, id, color, theme, style }) {
  console.log('publishing');

  const formData = new FormData();
  formData.append('project_json', JSON.stringify(storeJSON));
  formData.append('project_preview', preview);
  formData.append('title', name);
  formData.append('color', color);
  formData.append('theme', theme);
  formData.append('style', style);
  if(id) formData.append('project_id', id);

  await axios.post(`${constants.apiBaseUrl}/publish-project`, formData);

  console.log('published preview saved');
  return { id, status: 'saved' };
}

export async function printDesign({ front }) {
  const item_id = uuidV4();
  await axios.post(`${constants.apiBaseUrl}/cart-item`, {
    cart_item_id: item_id,
    front
  });

  return { item_id };
}

export const getPreview = async ({ id }) => {
  // const preview = await readFile(`designs/${id}.jpg`);
  let preview = await axios.get(`${constants.apiBaseUrl}/project-preview/${id}`, { responseType: 'blob' });
  return URL.createObjectURL(preview.data);
};

// const batchCall = (asyncFunction) => {
//   let cachedPromise = null;
//   return async (...args) => {
//     if (!cachedPromise) {
//       cachedPromise = asyncFunction(...args).catch((error) => {
//         // Reset cachedPromise on error to allow retry
//         cachedPromise = null;
//         throw error;
//       });
//     }
//     return cachedPromise;
//   };
// };

// let subDomainCache = null;
// const getPublicSubDomain = batchCall(async () => {
//   if (subDomainCache) {
//     return subDomainCache;
//   }
//   // fist we need to validate domain
//   const sites = await window.puter.hosting.list();
//   const user = await window.puter.auth.getUser();
//   const prefix = user.username + '-pltn-pld';
//   let subdomain = prefix;
//   const existingDomain = sites.find(
//     (site) => site.subdomain.indexOf(prefix) >= 0
//   );

//   if (existingDomain) {
//     subDomainCache = existingDomain.subdomain;
//     return existingDomain.subdomain;
//   }
//   let attempt = 1;
//   while (attempt < 10) {
//     const postfix = attempt > 1 ? `-${attempt}` : '';
//     subdomain = `${prefix}${postfix}`;
//     try {
//       await window.puter.fs.mkdir('uploads', { createMissingParents: true });
//       await window.puter.hosting.create(subdomain, 'uploads');
//       break;
//     } catch (error) {
//       attempt++;
//       continue;
//     }
//   }
//   if (attempt >= 10) {
//     throw new Error('Failed to create subdomain');
//   }
//   subDomainCache = subdomain;
//   return subdomain;
// });

export const listAssets = async () => {
  // const list = (await readKv('assets-list')) || [];
  const response = await axios.get(`${constants.apiBaseUrl}/user-upload-list`);
  const list = response.data?.data || [];
  for (const asset of list) {
    // asset.src = await getAssetSrc({ id: asset.id });
    asset.preview = await getAssetPreviewSrc({ id: asset.id });
  }
  return list;
};

export const getAssetSrc = async ({ id }) => {
  // if (window.puter.auth.isSignedIn()) {
  //   const subdomain = await getPublicSubDomain();
  //   return `https://${subdomain}.puter.site/${id}`;
  // } else {
  //   const file = await readFile(`uploads/${id}`);
  //   return URL.createObjectURL(file);
  // }

  let userUpload = await axios.get(`${constants.apiBaseUrl}/user-upload/${id}`, { responseType: 'blob' });
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(userUpload.data);
  });
  // return URL.createObjectURL(userUpload.data);
};

export const getAssetPreviewSrc = async ({ id }) => {
  // if (window.puter.auth.isSignedIn()) {
  //   const subdomain = await getPublicSubDomain();
  //   return `https://${subdomain}.puter.site/${id}-preview`;
  // } else {
  //   const file = await readFile(`uploads/${id}-preview`);
  //   return URL.createObjectURL(file);
  // }

  let userUploadPreview = await axios.get(`${constants.apiBaseUrl}/user-upload-preview/${id}`, { responseType: 'blob' });
  return URL.createObjectURL(userUploadPreview.data);
};

export const uploadAsset = async ({ file, preview, type='image' }) => {
  const list = await listAssets();
  const id = uuidV4();
  // await writeFile(`uploads/${id}`, file);
  // await writeFile(`uploads/${id}-preview`, preview);

  const formData = new FormData();
  formData.append('user_upload', file);
  formData.append('user_upload_preview', preview);
  if(id) formData.append('user_upload_id', id);

  await axios.post(`${constants.apiBaseUrl}/user-upload`, formData);

  list.push({ id, type });
  // await writeKv('assets-list', list);

  const src = await getAssetSrc({ id });
  const previewSrc = await getAssetPreviewSrc({ id });
  return { id, src, preview: previewSrc };
};

export const deleteAsset = async ({ id }) => {
  // const list = await listAssets();
  await axios.post(`${constants.apiBaseUrl}/user-upload-delete/${id}`);

  // const newList = list.filter((asset) => asset.id !== id);
  // await writeKv('assets-list', newList);
};
